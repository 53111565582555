import classNames from 'classnames';
import { InferProps } from 'prop-types';
import React from 'react';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { Check, X } from 'react-feather';
import { getScoreColor } from '@/utils/matchScores';
import { TalentMatchScores } from '@/modules/Jobs/types/talentMatch';
const defaultTalentReportProps = {};
export type TalentReportProps = InferProps<typeof defaultTalentReportProps> & {
  scores: TalentMatchScores;
};
export default function TalentReport({
  scores
}: TalentReportProps) {
  const reportScores = {
    Values: scores?.valuesScore || 0,
    'Company Size': scores?.companySize || 0,
    Language: scores?.languageScore || 0,
    Availability: scores?.availability || 0,
    'Tools & Technology': scores?.technologiesScore || 0,
    'Experience Level': scores?.experienceLevel || 0,
    'Expected Pay': scores?.expectedPay || 0,
    'Preferred Industry': scores?.preferredIndustry || 0
  };
  return <div className="flex flex-col" data-sentry-component="TalentReport" data-sentry-source-file="TalentReport.tsx">
      <h1 className="font-medium text-gray-700">Report</h1>
      <div className="mt-5 h-32 w-32">
        <CircularProgressbar value={scores.total || 0} text={`${scores.total || 0}%`} strokeWidth={15} styles={buildStyles({
        textColor: getScoreColor(scores.total || 0),
        pathColor: getScoreColor(scores.total || 0),
        textSize: 20,
        trailColor: `${getScoreColor(scores.total || 0)}2`
      })} data-sentry-element="CircularProgressbar" data-sentry-source-file="TalentReport.tsx" />
      </div>
      <div className="grid grid-cols-2 gap-4 py-5 sm:grid-cols-3">
        {Object.entries(reportScores).map(([key, value]) => {
        const numOfChecks = Object.entries(reportScores).length;
        const mainColor = `${getScoreColor((value + 1) * numOfChecks || 0)}`;
        const lightColor = `${getScoreColor((value + 1) * numOfChecks || 0)}2`;
        return <div className="flex flex-col items-start justify-between" key={key}>
              <div className="flex w-full items-start justify-start">
                <div className="mr-3 mt-2 flex rounded-full p-1"
            // eslint-disable-next-line react/forbid-dom-props
            style={{
              backgroundColor: mainColor
            }} />
                <span className="flex text-[14px] leading-normal text-gray-400">{key}</span>
              </div>

              {value >= 0 ? <div className={classNames('ml-5 mt-1.5 flex items-center justify-center rounded-full p-2')}
          // eslint-disable-next-line react/forbid-dom-props
          style={{
            backgroundColor: lightColor
          }}>
                  <Check size={18} color={mainColor} />
                </div> : <div className="ml-5 mt-1.5 flex items-center justify-center rounded-full bg-gray-100 p-2">
                  <X size={18} className="text-gray-400" />
                </div>}
            </div>;
      })}
      </div>
    </div>;
}