'use client';

import cn from '@/utils/cn';
import { useState } from 'react';
import { DragHandleDotsIcon } from '@/ui/Icons/Other';
import * as ResizablePrimitive from 'react-resizable-panels';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
const ResizablePanelGroup = ({
  className,
  ...props
}: React.ComponentProps<typeof ResizablePrimitive.PanelGroup>) => <ResizablePrimitive.PanelGroup className={cn('flex h-full w-full data-[panel-group-direction=vertical]:flex-col', className)} {...props} data-sentry-element="unknown" data-sentry-component="ResizablePanelGroup" data-sentry-source-file="Resizable.tsx" />;
const ResizablePanel = ResizablePrimitive.Panel;
const ResizableHandle = ({
  withHandle,
  withToggleSizeButton,
  className,
  onCollapsePress,
  onExpandPress,
  children,
  ...props
}: React.ComponentProps<typeof ResizablePrimitive.PanelResizeHandle> & {
  withToggleSizeButton?: boolean;
  withHandle?: boolean;
  onCollapsePress?: () => void;
  onExpandPress?: () => void;
  children?: React.ReactNode;
}) => {
  const [collapsed, setCollapsed] = useState(false);
  return <ResizablePrimitive.PanelResizeHandle className={cn('relative flex w-px items-center justify-center bg-border after:absolute after:inset-y-0 after:left-1/2 after:w-1 after:-translate-x-1/2 data-[panel-group-direction=vertical]:h-px data-[panel-group-direction=vertical]:w-full data-[panel-group-direction=vertical]:after:left-0 data-[panel-group-direction=vertical]:after:h-1 data-[panel-group-direction=vertical]:after:w-full data-[panel-group-direction=vertical]:after:-translate-y-1/2 data-[panel-group-direction=vertical]:after:translate-x-0 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring focus-visible:ring-offset-1 [&[data-panel-group-direction=vertical]>div]:rotate-90', 'border-r border-gray-200', className)} {...props} data-sentry-element="unknown" data-sentry-component="ResizableHandle" data-sentry-source-file="Resizable.tsx">
      {withHandle && <div className="z-10 flex h-4 w-3 items-center justify-center rounded-sm border bg-border">
          <DragHandleDotsIcon className="h-2.5 w-2.5" />
        </div>}
      {withToggleSizeButton && <button type="button" className="fixed top-12 z-10 hidden cursor-auto rounded-full bg-gray-50 p-1 text-gray-700 shadow hover:bg-blue-500 hover:text-white md:block" aria-label="Collapse panel" onClick={() => {
      setCollapsed(!collapsed);
      if (collapsed === false) onCollapsePress?.();else onExpandPress?.();
    }}>
          {collapsed === false ? <ChevronLeftIcon className="h-2.5 w-2.5" /> : <ChevronRightIcon className="h-2.5 w-2.5" />}
        </button>}

      {children}
    </ResizablePrimitive.PanelResizeHandle>;
};
export { ResizablePanelGroup, ResizablePanel, ResizableHandle };