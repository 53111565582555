'use client';

import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import Image from 'next/legacy/image';
import React, { Fragment } from 'react';
import welcomeArt from '@/public/assets/images/dashboard/welcome-art.svg';
import { useDarkMode } from 'usehooks-ts';
import { useAppSelector } from '@/store/hooks';
import { selectAuthState } from '@/store/selectors';
export default function WelcomeModal({
  showModal,
  onCloseDialog,
  handleWelcomePostAJob
}: {
  showModal: boolean;
  onCloseDialog: () => void;
  handleWelcomePostAJob: () => void;
}) {
  const {
    user
  } = useAppSelector(selectAuthState);
  const {
    isDarkMode
  } = useDarkMode();
  return <Transition.Root show={showModal} as={Fragment} data-sentry-element="unknown" data-sentry-component="WelcomeModal" data-sentry-source-file="WelcomeModal.tsx">
      <Dialog as="div" className="relative z-10" onClose={onCloseDialog} data-sentry-element="Dialog" data-sentry-source-file="WelcomeModal.tsx">
        <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0" data-sentry-element="unknown" data-sentry-source-file="WelcomeModal.tsx">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="scrollbar-primary fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 sm:scale-100" leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" data-sentry-element="unknown" data-sentry-source-file="WelcomeModal.tsx">
              <Dialog.Panel className={`relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-8 ${isDarkMode ? 'dark-mode dark:shadow-md' : ''}`} data-sentry-element="unknown" data-sentry-source-file="WelcomeModal.tsx">
                <div>
                  <div className="absolute right-0 top-0 z-30 pr-5 pt-5">
                    <button type="button" className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2" onClick={onCloseDialog}>
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" onClick={onCloseDialog} aria-hidden="true" data-sentry-element="XMarkIcon" data-sentry-source-file="WelcomeModal.tsx" />
                    </button>
                  </div>
                  <div className="mx-auto flex items-center justify-center">
                    <Image src={welcomeArt} alt="Welcome To Tech1M image" priority data-sentry-element="Image" data-sentry-source-file="WelcomeModal.tsx" />
                  </div>
                  <div className="text-center">
                    <Dialog.Title as="h3" className="text-lg font-medium capitalize leading-6 text-gray-900" data-sentry-element="unknown" data-sentry-source-file="WelcomeModal.tsx">
                      Welcome To Tech1M {user?.first_name || ''}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Start building your global team right away! You can begin by creating a job
                        or bringing in your talent now or you can come back to this later and head
                        into your dashboard.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="mt-8">
                  <button type="button" className="btn-primary" onClick={handleWelcomePostAJob}>
                    Create Job
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>;
}