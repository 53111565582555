'use client';

import React, { useCallback, useEffect } from 'react';
import { PostAsync } from '@/utils/Post';
import useProcessAsync from '@/hooks/useProcessAsync';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { selectAuthState } from '@/store/selectors';
import { setAuthState } from '@/store/slices';
import { User } from '@/store/types';
import LoaderSpinner from '@/ui/Loader/LoaderSpinner';
import useQueryParams from '@/hooks/useQueryParams';
import withSuspense from '@/hoc/withSuspense';
export default withSuspense(function AuthProtectedWrapper({
  className,
  children,
  user: userProp,
  skipSubscriptionCheck
}: {
  className?: string;
  children: React.ReactNode;
  user?: User;
  skipSubscriptionCheck?: boolean;
}) {
  const {
    user: userState
  } = useAppSelector(selectAuthState);
  const {
    processAsync: loadUser
  } = useProcessAsync();
  const dispatch = useAppDispatch();
  const {
    asPath,
    router
  } = useQueryParams();
  const user = userProp || userState;
  const checkSubscribedUser = useCallback((currentUser: typeof user) => {
    const isStripeCustomer = currentUser?.stripe_customer || currentUser?.company?.stripe_customer || currentUser?.subscription?.stripe_customer || '';
    const hasActiveSubscription = currentUser?.subscription?.status === 'active' || currentUser?.subscription?.status === 'trialing';
    const userIsSubscribed = isStripeCustomer && hasActiveSubscription;
    if (!userIsSubscribed && !skipSubscriptionCheck) {
      // user is not subscribed and not on pricing flow
      router.replace(`/pricing`);
    } else {
      // user is subscribed or on main protected pages
      dispatch(setAuthState({
        authenticated: true,
        user: currentUser
      }));
    }
  }, [dispatch, router, skipSubscriptionCheck]);
  useEffect(() => {
    if (user) {
      checkSubscribedUser(user);
    }
  }, [checkSubscribedUser, user, dispatch]);
  useEffect(() => {
    if (!user) {
      (async () => {
        const resData = await loadUser(async () => PostAsync({
          url: `/api/user`
        }));
        if (!resData?.data) {
          localStorage.setItem('authRedirect', asPath);
          await router.replace('/auth/login');
        } else {
          checkSubscribedUser(resData.data as unknown as User);
        }
      })();
    }
  }, [user, asPath, loadUser, dispatch, checkSubscribedUser, router]);
  if (!user) {
    return <div className="flex h-screen w-full items-center justify-center self-center bg-blue-50">
        {!user && <LoaderSpinner className="h-10 w-10" />}
      </div>;
  }
  return <div className={className}>{children}</div>;
});