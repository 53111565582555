import Image from 'next/legacy/image';
import React from 'react';
import logo from '@/public/assets/logos/tech1m.sidebar.logo.svg';
import logoCollapsed from '@/public/assets/logos/tech1m-small.png';
import SidebarFooter from './SidebarFooter';
import NavItems from './NavItems';
import { SidebarSections } from '../types';
import Link from 'next/link';
import ToggleDarkModeButton from '@/ui/ToggleDarkModeButton';
import cn from '@/utils/cn';
type MobileSidebarProps = {
  navSections: SidebarSections;
  isCollapsed: boolean;
};
export default function DesktopStaticSidebar({
  navSections,
  isCollapsed
}: MobileSidebarProps) {
  return <div className="inset-y-0 hidden h-full w-auto overflow-x-hidden bg-gray-50 md:flex md:flex-col lg:z-50" data-sentry-component="DesktopStaticSidebar" data-sentry-source-file="DesktopStaticSidebar.tsx">
      {/* Sidebar component, swap this element with another sidebar if you like */}
      <div className="relative flex min-h-0 flex-1 flex-col bg-white">
        <div className="scrollbar-primary flex flex-1 flex-col overflow-y-auto overflow-x-hidden pt-5">
          <div className={cn('flex flex-shrink-0 items-center justify-between px-4', isCollapsed ? 'flex-col justify-center gap-y-4' : 'justify-between')}>
            {isCollapsed ? <Link href="/dashboard" className="mt-2 h-8 w-auto">
                <Image src={logoCollapsed} className="flex items-center justify-center" alt="Tech1M Logo" width={32} height={32} priority />
              </Link> : <Link href="/dashboard" className="mt-2 h-8 w-auto">
                <Image src={logo} alt="Tech1M Logo" height={32} priority />
              </Link>}
            <div className="inline-block text-left" data-headlessui-state="">
              <div>
                <ToggleDarkModeButton data-sentry-element="ToggleDarkModeButton" data-sentry-source-file="DesktopStaticSidebar.tsx" />
              </div>
            </div>
            {/* <ToggleDarkModeButton /> */}
          </div>

          <NavItems navSections={navSections} isCollapsed={isCollapsed} data-sentry-element="NavItems" data-sentry-source-file="DesktopStaticSidebar.tsx" />
        </div>
        <div className="bottom-0 mt-1 w-full bg-white">
          <SidebarFooter isDesktop isCollapsed={isCollapsed} data-sentry-element="SidebarFooter" data-sentry-source-file="DesktopStaticSidebar.tsx" />{' '}
          {/* Hack to show SideFooter Correctly */}
        </div>
      </div>
    </div>;
}