/* eslint-disable max-len */
/* eslint-disable react/forbid-dom-props */
import { XMarkIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import React from 'react';
import { useAppDispatch } from '@/store/hooks';
import { announcementActions } from '@/store/slices/announcement';
import cn from '@/utils/cn';
import Link from 'next/link';
type Props = React.ComponentProps<'div'> & {
  // id?: string;
  text: string;
  bgClassName?: string;
  closeable?: boolean;
  link?: {
    href: string;
    text: string;
  };
};
export default function Announcement({
  className,
  text,
  bgClassName,
  link,
  closeable = true
}: Props) {
  const dispatch = useAppDispatch();
  // const bgClass = classNames(
  //   `aspect-[577/310] w-[36.0625rem] bg-gradient-to-tr  opacity-30`,
  //   'from-[#ff80b5] to-[#9089fc]',
  //   bgClassName
  // );

  const handleClose = () => {
    dispatch(announcementActions.setGeneral(false));
  };
  return <div className={classNames(`flex w-full`, className)} data-sentry-component="Announcement" data-sentry-source-file="MobileAnnouncementBanner.tsx">
      <div className={cn(`relative isolate flex w-full items-center gap-x-6 overflow-hidden rounded-lg bg-gray-50 bg-gradient-to-tr from-[#ff80b555] to-[#9089fc55] px-6 py-2.5 sm:px-3.5 sm:before:flex`, bgClassName)}>
        <div className={cn('flex flex-wrap items-center gap-x-4 gap-y-2', 'w-full justify-center')}>
          <p className="text-sm font-medium leading-6 text-gray-800">
            <span className="font-medium">{text}</span>
            {link && <>
                <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                  <circle cx={1} cy={1} r={1} />
                </svg>
                <Link href={link.href} className="flex-none px-1.5 py-1 text-sm font-semibold text-gray-800 hover:text-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900">
                  {link.text} <span aria-hidden="true">&rarr;</span>
                </Link>
              </>}
          </p>
        </div>

        {closeable && <div className="flex flex-1 justify-end">
            <button type="button" className="-m-3 p-3 focus-visible:outline-offset-[-4px]" onClick={handleClose}>
              <span className="sr-only">Dismiss</span>
              <XMarkIcon className="h-5 w-5 text-gray-900" aria-hidden="true" />
            </button>
          </div>}
      </div>
    </div>;
}