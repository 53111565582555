type AppLocalStorageKey =
  | 'authRedirect'
  | 'welcomeStatus'
  | 'user'
  | 'userData'
  | 'userFormDrafts'
  | 'jobPostingDetails'
  | 'jobPostingTextQuestions'
  | 'jobPostingVideoQuestions'
  | 'jobPostingCompliance'
  | 'aiJobPostConsent';

export const localStorageRemove = (item: AppLocalStorageKey) => {
  localStorage.removeItem(item);
};

export const clearJobPostingLocalStorage = () => {
  localStorageRemove('jobPostingDetails');
  localStorageRemove('jobPostingTextQuestions');
  localStorageRemove('jobPostingVideoQuestions');
  localStorageRemove('jobPostingCompliance');
};

export const clearAllLocalStorage = () => {
  // localStorageRemove('authRedirect');
  localStorageRemove('user');
  localStorageRemove('userData');
  localStorageRemove('userFormDrafts');
  localStorageRemove('jobPostingDetails');
  localStorageRemove('jobPostingTextQuestions');
  localStorageRemove('jobPostingVideoQuestions');
  localStorageRemove('jobPostingCompliance');
  localStorageRemove('aiJobPostConsent');
};
