import React from 'react';
import { useIsMounted } from 'usehooks-ts';
import { ResponseData, ResponseError } from '@/types';
import { ScreenError } from '@/constants/error/types';

export type ReturnResponse = (ResponseData & ResponseError) | undefined;

type ProcessFunction = () => Promise<ResponseError & ResponseData>;

type ProcessAsync = (processFunction: ProcessFunction) => Promise<ReturnResponse>;

type ReturnError = (ScreenError & ResponseError) | null;

interface ProcessAsyncControl {
  error: ReturnError;
  setError: (error: ReturnError) => void;
  isLoading: boolean;
  isSuccessful: boolean;
  result: (ResponseData & ResponseError) | undefined;
  processAsync: ProcessAsync;
}

export default function useProcessAsync(): ProcessAsyncControl {
  const isMounted = useIsMounted();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isSuccessful, setIsSuccessful] = React.useState<boolean>(false);
  const [error, setError] = React.useState<ReturnError>(null);
  const [result, setResult] = React.useState<(ResponseData & ResponseError) | undefined>();

  const processAsync: ProcessAsync = React.useCallback(
    async (processFunction: ProcessFunction) => {
      setIsLoading(true);
      setError(null);
      setIsSuccessful(false);
      let res;
      try {
        res = await processFunction();

        if (isMounted()) {
          setIsLoading(false);
          setError(null);
          if (
            res &&
            (res.errors ||
              res.status === 'error' ||
              res.meta?.status === 'error' ||
              res.message === 'error')
          ) {
            setError(res as ResponseError);
          } else {
            setIsSuccessful(true);
            setResult(res);
          }
        }
      } catch (err: any) {
        if (isMounted()) {
          setIsLoading(false);
          setIsSuccessful(false);
          setError({ ...err, message: err?.message, data: err?.data });
        }
      }
      return res;
    },
    [isMounted]
  );

  return {
    isLoading,
    error,
    isSuccessful,
    result,
    processAsync,
    setError,
  };
}
