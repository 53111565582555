'use client';

import { SunIcon } from '@heroicons/react/20/solid';
import { MoonIcon } from '@heroicons/react/24/solid';
import { useTernaryDarkMode, useDarkMode } from 'usehooks-ts';
import ButtonDropdown from './Buttons/ButtonDropdown';
import { SystemThemeIcon } from './Icons/Other';
export default function ToggleDarkModeButton() {
  const {
    isDarkMode,
    toggle
  } = useDarkMode();
  return <button onClick={toggle} name="Toggle Theme" className="rounded-md border border-gray-100 bg-white p-1.5 hover:bg-gray-50" data-sentry-component="ToggleDarkModeButton" data-sentry-source-file="ToggleDarkModeButton.tsx">
      {isDarkMode === false ? <SunIcon className="h-4 w-4 text-yellow-600" /> : <MoonIcon className="h-4 w-4 text-gray-900" />}
    </button>;
}
export function ToggleDarkModeSystemButton() {
  const {
    setTernaryDarkMode,
    ternaryDarkMode
  } = useTernaryDarkMode();
  return <ButtonDropdown title="Toggle Theme" options={[{
    title: 'Light',
    onClick: () => setTernaryDarkMode('light'),
    selected: ternaryDarkMode === 'light'
  }, {
    title: 'Dark',
    onClick: () => setTernaryDarkMode('dark'),
    selected: ternaryDarkMode === 'dark'
  }, {
    title: 'System',
    onClick: () => setTernaryDarkMode('system'),
    selected: ternaryDarkMode === 'system'
  }]} icon={
  // eslint-disable-next-line no-nested-ternary
  ternaryDarkMode === 'light' ? <SunIcon className="h-4 w-4 text-yellow-600" /> : ternaryDarkMode === 'dark' ? <MoonIcon className="h-4 w-4 text-gray-900" /> : <SystemThemeIcon className="h-4 w-4 text-gray-900" />} className="rounded-md border border-gray-100 bg-white p-1.5 hover:bg-gray-50" data-sentry-element="ButtonDropdown" data-sentry-component="ToggleDarkModeSystemButton" data-sentry-source-file="ToggleDarkModeButton.tsx" />;
}