'use client';

import type { AppLayoutProps } from './types';
import AuthProtected from './partials/AuthProtectedWrapper';
import cn from '@/utils/cn';
import useRootPageLayout from '@/modules/Sidebar/hooks/useRootPageLayout';
import { WelcomeModal } from '@/components/Modals';
import { useMediaQuery } from 'usehooks-ts';
import { ResizablePanelGroup, ResizablePanel, ResizableHandle } from '@/ui/Resizable';
import { useEffect, useRef } from 'react';
import { ImperativePanelHandle } from 'react-resizable-panels';
import DesktopStaticSidebar from '@/modules/Sidebar/components/DesktopStaticSidebar';
import MobileSidebar from '@/modules/Sidebar/components/MobileSidebar';
import FeedbackContainer from '@/modules/ExternalServices/components/FeedbackContainer';
import MobileAnnouncementBanner from './banners/MobileAnnouncementBanner';
import MainWrapper from '@/layouts/partials/MainWrapper';
import { useLocalStorage } from 'usehooks-ts';
function PageContent({
  children
}: {
  children: React.ReactNode;
}) {
  const {
    showWelcomeModal,
    handleWelcomePostAJob,
    closeWelcomeModal
  } = useRootPageLayout();
  return <>
      <div className="scrollbar-primary flex flex-1 flex-col overflow-y-scroll bg-gray-50 backdrop-blur backdrop-filter md:h-full">
        {children}
      </div>
      {showWelcomeModal && <WelcomeModal showModal={showWelcomeModal} handleWelcomePostAJob={handleWelcomePostAJob} onCloseDialog={closeWelcomeModal} />}
    </>;
}
export default function AppLayout({
  sidebar = false,
  children,
  skipSubscriptionCheck = undefined,
  className
}: AppLayoutProps) {
  const [sidebarSize, setSidebarSize] = useLocalStorage('sidebarSize', 20);
  const isDesktop = useMediaQuery('(min-width: 768px)');
  const {
    isDarkMode
  } = {
    isDarkMode: false
  }; //useDarkMode();

  useEffect(() => {
    if (!isDarkMode) document.body.classList.remove('dark');else document.body.classList.add('dark');
    return () => {
      document.body.classList.remove('dark');
    };
  }, [isDarkMode]);
  const ref = useRef<ImperativePanelHandle>(null);
  const collapseSidebar = () => {
    const sidebar = ref.current;
    if (sidebar) {
      sidebar.collapse();
    }
  };
  const expandSidebar = () => {
    const sidebar = ref.current;
    if (sidebar) {
      sidebar.expand();
    }
  };
  const {
    sidebarOpen,
    setSidebarOpen,
    navSections,
    announceVisible,
    announcementText
  } = useRootPageLayout();
  return <AuthProtected skipSubscriptionCheck={skipSubscriptionCheck} className={cn('App-content flex h-screen w-screen bg-white', className, isDarkMode ? 'dark-mode' : '')} data-sentry-element="AuthProtected" data-sentry-component="AppLayout" data-sentry-source-file="AppLayout.tsx">
      <MainWrapper data-sentry-element="MainWrapper" data-sentry-source-file="AppLayout.tsx">
        {/* {announceVisible && (
          <AnnouncementBanner
            title={''}
            text={announcementText}
            closeable={true}
            link={{ text: 'Upgrade Now', href: '/settings?tab=Billing' }}
          />
         )} */}
        <FeedbackContainer data-sentry-element="FeedbackContainer" data-sentry-source-file="AppLayout.tsx">
          {Boolean(sidebar && isDesktop) && <ResizablePanelGroup direction="horizontal" className="h-[calc(100%-36px)]">
              <ResizablePanel ref={ref} collapsible={true} collapsedSize={8} minSize={8} maxSize={25} defaultSize={20} onCollapse={() => setSidebarSize(8)} onExpand={() => setSidebarSize(20)} onResize={setSidebarSize}>
                <DesktopStaticSidebar navSections={navSections} isCollapsed={sidebarSize <= 14} />
              </ResizablePanel>
              <ResizableHandle withHandle withToggleSizeButton onCollapsePress={collapseSidebar} onExpandPress={expandSidebar} />
              <ResizablePanel className="">
                <PageContent>
                  <div className="">{children}</div>
                </PageContent>
              </ResizablePanel>
            </ResizablePanelGroup>}

          {Boolean(!isDesktop && sidebar) && <div className="scrollbar-primary flex flex-col">
              <MobileSidebar navSections={navSections} onCloseSidebar={() => setSidebarOpen(false)} openSidebar={sidebarOpen} />
              <PageContent>
                {announceVisible && <MobileAnnouncementBanner className="p-4" text={announcementText} closeable={true} link={{
              text: 'Upgrade Now',
              href: '/settings?tab=Billing'
            }} />}
                <div className="">{children}</div>
              </PageContent>
            </div>}

          {!sidebar && <PageContent>{children}</PageContent>}
        </FeedbackContainer>
      </MainWrapper>
    </AuthProtected>;
}