import { type ReactNode } from 'react';
import cn from '@/utils/cn';
type IMainProps = {
  children: ReactNode;
  className?: string;
};
export default function MainWrapper({
  className,
  children
}: IMainProps) {
  return <div className={cn('App-content scrollbar-w-1 scrollbar-primary snap-none leading-6', className || '')} data-sentry-component="MainWrapper" data-sentry-source-file="MainWrapper.tsx">
      {children}
    </div>;
}