import { createSlice, PayloadAction, AnyAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

// Type for our state
export interface AnnouncementState {
  generalVisible: boolean;
  visibleAnnouncements: string[];
}

// Initial state
const initialState: AnnouncementState = {
  generalVisible: true,
  visibleAnnouncements: [],
};

// Actual Slice
export const announcementSlice = createSlice({
  name: 'announcement',

  initialState,

  reducers: {
    reset: () => initialState,

    setGeneral(state, action: PayloadAction<AnnouncementState['generalVisible']>) {
      state.generalVisible = action.payload;
    },
  }, // Special reducer for hydrating the state. Special case for next-redux-wrapper

  extraReducers: (builder) => {
    builder.addCase(HYDRATE, (state: AnnouncementState, action: AnyAction) => {
      return {
        ...state,
        ...action.payload.announcement,
      } as AnnouncementState;
    });
  },
});

export const announcementActions = announcementSlice.actions;

export default announcementSlice.reducer;
