/* eslint-disable import/prefer-default-export */
import { ScoreColor } from '@/constants/Enums';

export const getScoreColor = (percentScore: number) => {
  if (percentScore >= 90) {
    return ScoreColor['90+'];
  }
  if (percentScore >= 80) {
    return ScoreColor['80+'];
  }
  if (percentScore >= 61) {
    return ScoreColor['61+'];
  }
  if (percentScore >= 41) {
    return ScoreColor['41+'];
  }
  return ScoreColor['0+'];
};
