'use client';

/* eslint-disable camelcase */
import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import React, { Fragment, useMemo, useState } from 'react';
import { Activity, Home, Minimize2 } from 'react-feather';
import { getMonthName } from '@/utils/date.helper';
import { RecommendedTalentsResponseData } from '@/modules/Jobs/types/recommendedTalentsResponse';
import { TalentProfile } from './components';
import { useDarkMode } from 'usehooks-ts';
export default function TalentDetailModal({
  showModal,
  onCloseDialog,
  talent
}: {
  showModal: boolean;
  onCloseDialog: () => void;
  talent: RecommendedTalentsResponseData['data'][0];
}) {
  const [currentPage, setCurrentPage] = useState('Profile');
  const {
    first_name: firstName,
    last_name: lastName,
    headline,
    work_experience: workExperience,
    avatar
  } = talent?.attributes || {};

  // const { skills, job_level, company_size, values, language, industry, salary_expectation } =
  //   scores.match_percentage_breakdown;

  const navigation = useMemo(() => [{
    name: 'Profile',
    Icon: Home,
    onClickSection: () => {
      return null;
    }
  }, {
    name: 'Report',
    Icon: Activity,
    onClickSection: () => {
      return null;
    }
  }], []);
  const {
    isDarkMode
  } = useDarkMode();
  return <Transition.Root show={showModal} as={Fragment} data-sentry-element="unknown" data-sentry-component="TalentDetailModal" data-sentry-source-file="TalentDetailModal.tsx">
      <Dialog as="div" className="relative z-10" onClose={onCloseDialog} data-sentry-element="Dialog" data-sentry-source-file="TalentDetailModal.tsx">
        <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0" data-sentry-element="unknown" data-sentry-source-file="TalentDetailModal.tsx">
          <div className="fixed inset-0 bg-gray-700 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="scrollbar-primary fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 sm:scale-100" leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" data-sentry-element="unknown" data-sentry-source-file="TalentDetailModal.tsx">
              <Dialog.Panel className={`relative w-full transform overflow-hidden rounded-xl bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl md:max-w-2xl ${isDarkMode ? 'dark-mode dark:shadow-md' : ''}`} data-sentry-element="unknown" data-sentry-source-file="TalentDetailModal.tsx">
                <div className="flex h-full w-full flex-col md:flex-row">
                  <div className="min-w-[160px] border-r border-gray-200 bg-gray-50 p-5 py-2 md:py-5">
                    <div className="flex w-full justify-end pt-1 md:hidden">
                      <Minimize2 className="text-gray-400" size={18} onClick={() => onCloseDialog()} data-sentry-element="Minimize2" data-sentry-source-file="TalentDetailModal.tsx" />
                    </div>
                    <h1 className="font-medium text-gray-400">Viewing</h1>
                    <nav className="mt-4 flex-1 space-y-1">
                      {navigation.map(({
                      name,
                      Icon
                    }) => {
                      const isCurrent = name === currentPage;
                      return <div role="button" key={name} onClick={() => setCurrentPage(name)} className={classNames(isCurrent ? 'text-primary-default' : 'text-gray-500 hover:bg-gray-50 hover:text-gray-900', 'group flex items-center py-2 pr-8 font-medium')} aria-hidden="true">
                            <Icon className={classNames(isCurrent ? 'text-primary-default' : 'text-gray-500 group-hover:text-gray-600', 'mr-3 h-6 w-6 flex-shrink-0')} aria-hidden="true" />
                            {name}
                          </div>;
                    })}
                    </nav>
                  </div>
                  <div className="flex flex-1 flex-col p-5 px-8 sm:px-5">
                    <div className="hidden w-full justify-end md:flex">
                      <Minimize2 className="text-gray-400" size={18} onClick={() => onCloseDialog()} data-sentry-element="Minimize2" data-sentry-source-file="TalentDetailModal.tsx" />
                    </div>
                    {currentPage === 'Profile' ? <TalentProfile name={`${firstName || 'Name'} ${lastName || 'Name'}`} title={headline || 'Headline'} workExperiences={workExperience.map(wE => ({
                    title: wE.role || 'Role',
                    companyName: wE.company,
                    dateRange: `${getMonthName(wE.start_date?.month || 0)} ${wE.start_date?.year || 'Year'} - ${!wE.end_date ? 'Present' : `${getMonthName(wE.end_date?.month || 0)} ${wE.end_date?.year || 'Year'}`}  `
                  }))}
                  // eslint-disable-next-line max-len
                  avatarUrl={avatar || ''} /> : <div />
                  // <TalentReport
                  //   scores={{
                  //     salaryExpectation: salary_expectation,
                  //     technologiesScore: skills,
                  //     experienceLevel: job_level,
                  //     availability: 0,
                  //     companySize: company_size,
                  //     valuesScore: values,
                  //     languageScore: language,
                  //     total: scores.total_score,
                  //     preferredIndustry: industry,
                  //     expectedPay: salary_expectation,
                  //   }}
                  // />
                  }
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>;
}