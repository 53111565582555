import { User } from '@/store/types';
import { env } from '@/env.mjs';
import { UserbackProvider } from '@userback/react';
export default function Userback({
  children,
  user
}: {
  children: React.ReactNode;
  user?: User;
}) {
  // identify your logged-in users (optional)
  const user_data = {
    id: user?.userId,
    company: user?.company?.name,
    company_id: user?.company?.id
  };
  return <UserbackProvider token={env.NEXT_PUBLIC_USERBACK_TOKEN} options={{
    email: user?.email || '',
    name: user?.first_name + ' ' + user?.last_name,
    custom_data: user_data,
    autohide: true
  }} delayInit={false} widgetSettings={{}} data-sentry-element="UserbackProvider" data-sentry-component="Userback" data-sentry-source-file="Userback.tsx">
      {children}
    </UserbackProvider>;
}