'use client';
import { useParams, usePathname, useRouter, useSearchParams } from 'next/navigation';

export default function useQueryParams<T>() {
  const router = useRouter();

  const pathname = usePathname();

  const searchParams = useSearchParams();

  const dynamicParams = useParams();

  const urlSearchParams = new URLSearchParams(searchParams?.toString());
  // Construct the equivalent of asPath
  const asPath = `${pathname}${searchParams.toString() ? `?${searchParams.toString()}` : ''}`;

  // The equivalent of route is just the pathname
  const route = pathname;

  function setQueryParams(params: Partial<T>) {
    Object.entries(params).forEach(([key, value]) => {
      if (value === undefined || value === null) {
        urlSearchParams.delete(key);
      } else {
        urlSearchParams.set(key, String(value));
      }
    });

    const search = urlSearchParams.toString();

    const query = search ? `?${search}` : '';
    // replace since we don't want to build a history
    router.replace(`${pathname}${query}`);
  }

  const query: { [x: string]: string } = {};
  searchParams.forEach((val, key) => {
    query[key] = val;
  });

  return {
    queryParams: searchParams,
    setQueryParams,
    query: { ...dynamicParams, ...query },
    asPath,
    route,
    router,
    dynamicParams,
    pathname,
  };
}
