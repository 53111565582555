'use client';

import { useAppSelector } from '@/store/hooks';
import { selectAuthState } from '@/store/selectors';
import Userback from '../services/Userback/Userback';
export default function AnalyticsContainer({
  children
}: {
  children: React.ReactNode;
}) {
  const {
    user
  } = useAppSelector(selectAuthState);
  return <Userback key={user?.userId} user={user ?? undefined} data-sentry-element="Userback" data-sentry-component="AnalyticsContainer" data-sentry-source-file="FeedbackContainer.tsx">
      {children}
    </Userback>;
}