export const SystemThemeIcon = ({
  size,
  className
}: {
  size?: number;
  className?: any;
  round?: boolean;
}) => <div className={className} style={{
  width: size || 24,
  height: size || 24
}} data-sentry-component="SystemThemeIcon" data-sentry-source-file="Other.tsx">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" data-sentry-element="svg" data-sentry-source-file="Other.tsx">
      <path d="M37.081 2.417a1 1 0 0 0-1 1v57.166a1 1 0 1 0 2 0V3.417a1 1 0 0 0-1-1zm8.46 14.198a15.707 15.707 0 0 0-3.193.336 1 1 0 0 0-.368 1.797 15.551 15.551 0 0 1 6.64 12.118 15.665 15.665 0 0 1-7.549 14.105 1 1 0 0 0 .236 1.815 14.936 14.936 0 0 0 4.909.581 15.29 15.29 0 0 0 10.669-4.984 15.27 15.27 0 0 0 4.02-11.063 15.357 15.357 0 0 0-15.364-14.705zm9.868 24.418a13.3 13.3 0 0 1-9.283 4.337c-.206.009-.41.014-.613.014h-.001c-.485 0-.967-.026-1.443-.078a17.659 17.659 0 0 0 6.548-14.528c-.208-4.702-2.224-9.017-5.612-12.151A13.357 13.357 0 0 1 58.906 31.41a13.29 13.29 0 0 1-3.497 9.623zM19.038 22.205c-5.401 0-9.795 4.394-9.795 9.795 0 5.402 4.394 9.797 9.795 9.797s9.795-4.395 9.795-9.797c0-5.401-4.394-9.795-9.795-9.795zm0 17.592c-4.298 0-7.795-3.498-7.795-7.797 0-4.298 3.497-7.795 7.795-7.795s7.795 3.497 7.795 7.795c0 4.299-3.497 7.797-7.795 7.797zm0-17.803a1 1 0 0 0 1-1v-3.951a1 1 0 1 0-2 0v3.951a1 1 0 0 0 1 1zm0 20.012a1 1 0 0 0-1 1v3.951a1 1 0 1 0 2 0v-3.951a1 1 0 0 0-1-1zM33.994 31h-3.951a1 1 0 1 0 0 2h3.951a1 1 0 1 0 0-2zM9.032 32a1 1 0 0 0-1-1H4.081a1 1 0 1 0 0 2h3.951a1 1 0 0 0 1-1z" data-sentry-element="path" data-sentry-source-file="Other.tsx" />
      <path d="M10.727 24.715a.998.998 0 0 0 1.414.036 1 1 0 0 0 .036-1.414l-2.723-2.863a1 1 0 0 0-1.45 1.379zm16.621 14.57a1 1 0 0 0-1.45 1.379l2.723 2.863a.998.998 0 0 0 1.414.036 1 1 0 0 0 .036-1.414zm-16.621.002-2.723 2.861a1.001 1.001 0 0 0 1.449 1.378l2.723-2.861c.38-.4.365-1.033-.035-1.414a1.005 1.005 0 0 0-1.414.036zm15.897-14.262a.998.998 0 0 0 .725-.311l2.723-2.863a1.001 1.001 0 0 0-1.45-1.379l-2.723 2.863a1 1 0 0 0 .725 1.69z" data-sentry-element="path" data-sentry-source-file="Other.tsx" />
    </svg>
  </div>;
export const CollapseMenuIcon = ({
  size,
  className
}: {
  size?: number;
  className?: any;
  round?: boolean;
}) => <svg className={className} width={size || 24} height={size || 24} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-sentry-element="svg" data-sentry-component="CollapseMenuIcon" data-sentry-source-file="Other.tsx">
    <path d="M75 90h80c8.284 0 15-6.716 15-15s-6.716-15-15-15H75c-8.284 0-15 6.716-15 15s6.716 15 15 15zm0 60h80c8.284 0 15-6.716 15-15s-6.716-15-15-15H75c-8.284 0-15 6.716-15 15s6.716 15 15 15zm0 60h80c8.284 0 15-6.716 15-15s-6.716-15-15-15H75c-8.284 0-15 6.716-15 15s6.716 15 15 15z" data-sentry-element="path" data-sentry-source-file="Other.tsx" />
    <path d="M457 0H55C24.673 0 0 24.673 0 55v402c0 30.327 24.673 55 55 55h402c30.327 0 55-24.673 55-55V55c0-30.327-24.673-55-55-55zM200 482H55c-13.785 0-25-11.215-25-25V55c0-13.785 11.215-25 25-25h145zm282-241H313.213l24.394-24.394c5.858-5.858 5.858-15.355 0-21.213s-15.355-5.858-21.213 0l-50 50c-5.858 5.858-5.858 15.355 0 21.213l50 50c5.858 5.858 15.355 5.858 21.213 0s5.858-15.355 0-21.213L313.213 271H482v186c0 13.785-11.215 25-25 25H230V30h227c13.785 0 25 11.215 25 25z" data-sentry-element="path" data-sentry-source-file="Other.tsx" />
  </svg>;
export const DragHandleDotsIcon = ({
  size = 15,
  className
}: {
  size?: number;
  className?: any;
  round?: boolean;
}) => <svg className={className} width={size} height={size} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="DragHandleDotsIcon" data-sentry-source-file="Other.tsx">
    <path d="M5.5 4.625C6.12132 4.625 6.625 4.12132 6.625 3.5C6.625 2.87868 6.12132 2.375 5.5 2.375C4.87868 2.375 4.375 2.87868 4.375 3.5C4.375 4.12132 4.87868 4.625 5.5 4.625ZM9.5 4.625C10.1213 4.625 10.625 4.12132 10.625 3.5C10.625 2.87868 10.1213 2.375 9.5 2.375C8.87868 2.375 8.375 2.87868 8.375 3.5C8.375 4.12132 8.87868 4.625 9.5 4.625ZM10.625 7.5C10.625 8.12132 10.1213 8.625 9.5 8.625C8.87868 8.625 8.375 8.12132 8.375 7.5C8.375 6.87868 8.87868 6.375 9.5 6.375C10.1213 6.375 10.625 6.87868 10.625 7.5ZM5.5 8.625C6.12132 8.625 6.625 8.12132 6.625 7.5C6.625 6.87868 6.12132 6.375 5.5 6.375C4.87868 6.375 4.375 6.87868 4.375 7.5C4.375 8.12132 4.87868 8.625 5.5 8.625ZM10.625 11.5C10.625 12.1213 10.1213 12.625 9.5 12.625C8.87868 12.625 8.375 12.1213 8.375 11.5C8.375 10.8787 8.87868 10.375 9.5 10.375C10.1213 10.375 10.625 10.8787 10.625 11.5ZM5.5 12.625C6.12132 12.625 6.625 12.1213 6.625 11.5C6.625 10.8787 6.12132 10.375 5.5 10.375C4.87868 10.375 4.375 10.8787 4.375 11.5C4.375 12.1213 4.87868 12.625 5.5 12.625Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd" data-sentry-element="path" data-sentry-source-file="Other.tsx"></path>
  </svg>;