'use client';

import { Dialog, Transition } from '@headlessui/react';
import Image from 'next/legacy/image';
import PropTypes, { InferProps } from 'prop-types';
import React, { Fragment, useState } from 'react';
import logo from '@/public/assets/logos/tech1m.sidebar.logo.svg';
import SidebarFooter from './SidebarFooter';
import NavItems from './NavItems';
import { SidebarSections } from '../types';
import Link from 'next/link';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import cn from '@/utils/cn';
import ToggleDarkModeButton from '@/ui/ToggleDarkModeButton';
const mobileSidebarProps = {
  openSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};
type MobileSidebarProps = InferProps<typeof mobileSidebarProps> & {
  navSections?: SidebarSections;
  children?: React.ReactNode;
};
export default function MobileSidebar({
  navSections,
  children
}: MobileSidebarProps) {
  const [toggleSidebar, setToggleSidebar] = useState(false);
  return <>
      <div className="sticky left-0 top-0 z-40 flex h-14 w-full flex-row-reverse justify-between bg-white py-1 shadow-sm sm:pl-3 sm:pt-3 md:hidden">
        <button type="button" className={cn('ml-1 inline-flex h-[42] w-[42] items-center', 'justify-center rounded-md text-gray-500 hover:text-gray-900', 'focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500')} onClick={() => setToggleSidebar(p => !p)}>
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon className="mx-2 h-6 w-6" aria-hidden="true" data-sentry-element="Bars3Icon" data-sentry-source-file="MobileSidebar.tsx" />
        </button>

        <Link href="/dashboard" className="flex flex-shrink-0 items-center px-4" data-sentry-element="Link" data-sentry-source-file="MobileSidebar.tsx">
          <Image src={logo} alt="Tech1M Logo" data-sentry-element="Image" data-sentry-source-file="MobileSidebar.tsx" />
        </Link>
      </div>
      <Transition.Root show={toggleSidebar} as={Fragment} data-sentry-element="unknown" data-sentry-source-file="MobileSidebar.tsx">
        {/* MOBILE SIDEBAR */}
        <Dialog as="div" className="relative z-50" style={{
        zIndex: 70
      }} onClose={() => setToggleSidebar(p => !p)} data-sentry-element="Dialog" data-sentry-source-file="MobileSidebar.tsx">
          <Transition.Child as={Fragment} enter="transition-opacity ease-linear duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="transition-opacity ease-linear duration-300" leaveFrom="opacity-100" leaveTo="opacity-0" data-sentry-element="unknown" data-sentry-source-file="MobileSidebar.tsx">
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child as={Fragment} enter="transition ease-in-out duration-300 transform" enterFrom="-translate-x-full" enterTo="translate-x-0" leave="transition ease-in-out duration-300 transform" leaveFrom="translate-x-0" leaveTo="-translate-x-full" data-sentry-element="unknown" data-sentry-source-file="MobileSidebar.tsx">
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white" data-sentry-element="unknown" data-sentry-source-file="MobileSidebar.tsx">
                <Transition.Child as={Fragment} enter="ease-in-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in-out duration-300" leaveFrom="opacity-100" leaveTo="opacity-0" data-sentry-element="unknown" data-sentry-source-file="MobileSidebar.tsx">
                  <div className="absolute right-0 top-0 -mr-12 pt-2">
                    <button type="button" className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" onClick={() => setToggleSidebar(p => !p)}>
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" data-sentry-element="XMarkIcon" data-sentry-source-file="MobileSidebar.tsx" />
                    </button>
                  </div>
                </Transition.Child>
                {children ? children : <div className="scrollbar-primary dark-mode h-0 flex-1 overflow-y-auto bg-white pb-4 pt-5">
                    <div className="flex justify-between pr-4">
                      <Link href="/dashboard" className="flex flex-shrink-0 items-center px-4">
                        <Image src={logo} alt="Tech1M Logo" />
                      </Link>

                      <ToggleDarkModeButton />
                    </div>

                    <NavItems navSections={navSections || []} />
                    <SidebarFooter />
                  </div>}
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0">
              {/* Force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>;
}