import Image from 'next/legacy/image';
import { InferProps } from 'prop-types';
import React from 'react';
const defaultTalentProfileProps = {};
export type TalentProfileProps = InferProps<typeof defaultTalentProfileProps> & {
  avatarUrl: string;
  name: string;
  title: string;
  workExperiences: {
    title: string;
    companyName: string;
    dateRange: string;
  }[];
};
export default function TalentProfile({
  avatarUrl,
  name,
  title,
  workExperiences
}: TalentProfileProps) {
  return <div className="mt-1 flex w-full flex-1 flex-col" data-sentry-component="TalentProfile" data-sentry-source-file="TalentProfile.tsx">
      <div className="flex flex-col self-start text-lg">
        <div className="h-20 w-20 self-start overflow-clip rounded-full border-[3px] border-white shadow-lg">
          <Image src={avatarUrl} alt="" height={80} width={80} data-sentry-element="Image" data-sentry-source-file="TalentProfile.tsx" />
        </div>
        <h3 className="mt-4 font-semibold text-gray-900">{name}</h3>
        <h3 className="text-gray-500">{title}</h3>
      </div>
      <h3 className="mt-4 text-base font-semibold text-gray-800">Work Experience</h3>
      <div className="grid grid-cols-1 gap-2 pb-5 sm:grid-cols-2">
        {workExperiences.map(({
        title: workTitle,
        companyName,
        dateRange
      }) => {
        return <div key={dateRange} className="flex flex-col text-sm">
              <h3 className="mt-1 font-medium text-gray-600">{workTitle}</h3>
              <h3 className="text-gray-600">{companyName}</h3>
              <h4 className="text-gray-500">{dateRange}</h4>
            </div>;
      })}
      </div>
    </div>;
}