import { createEnv } from '@t3-oss/env-nextjs';
import { z } from 'zod';

export const env = createEnv({
  server: {
    JWT_SECRET_KEY: z.string().min(1),
    DB_HOST: z.string().min(1),
    DB_USER: z.string().min(1),
    DB_DATABASE: z.string().min(1),
    DB_PORT: z.string(),
    DB_PASSWORD: z.string().min(1),
    OPENAI_API_KEY: z.string().min(1),
    ANTHROPIC_API_KEY: z.string().min(1),
    PORTKEY_API_KEY: z.string().min(1),
    AI_DEFAULT_PROVIDER: z.string().min(1),
    UNSTRUCTURED_API_KEY: z.string().min(1),
    UNSTRUCTURED_API_URL: z.string().url(),
    USERBACK_SSO_KEY: z.string(),
    UPSTASH_URL: z.string().url(),
    UPSTASH_TOKEN: z.string().min(1),
    UPSTASH_VECTOR_URL: z.string().url(),
    UPSTASH_VECTOR_TOKEN: z.string().min(1),
    STRIPE_SECRET_KEY: z.string().min(1),
    ANALYSE: z
      .string()
      .refine((s) => s === 'true' || s === 'false')
      .transform((s) => s === 'true')
      .default('false')
      .optional(),
    NODE_ENV: z
      .string()
      .refine((s) => s === 'development' || s === 'production' || s === 'test')
      .default('production'),
  },
  client: {
    NEXT_PUBLIC_DEVTOOLS_ENABLED: z
      .string()
      .refine((s) => s === 'true' || s === 'false')
      .transform((s) => s === 'true')
      .default('false')
      .optional(),
    NEXT_PUBLIC_GOOGLE_MAPS_CALLBACK_NAME: z.string().min(1).default('googleMapsCallback'),
    NEXT_PUBLIC_USERBACK_PORTAL_URL: z
      .string()
      .url()
      .default('https://tech1m.ideas.userback.io/p/N5jg558Wx3'),
    NEXT_PUBLIC_UNIFIED_REGION: z
      .string()
      .refine((s) => s === 'eu' || s === 'us')
      .default('eu')
      .optional(),
    NEXT_PUBLIC_SENTRY_ORG: z.string().min(1).default('tedbreesocial'),
    NEXT_PUBLIC_SENTRY_PROJECT: z.string().min(1).default('tech1m-company-nextjs'),
    NEXT_PUBLIC_MICROSOFT_CLARITY_KEY: z.string().optional(),
    NEXT_PUBLIC_APP_URL: z.string().default('https://business.tech1m.com').optional(),
    NEXT_PUBLIC_BACKEND_URL: z.string().default('https://manage.tech1m.com').optional(),
    NEXT_PUBLIC_CAREER_PAGE_DOMAIN: z.string().default('careers.tech1m.com').optional(),
    NEXT_PUBLIC_FRESHPAINT_KEY: z.string().min(1),
    NEXT_PUBLIC_GOOGLE_MAPS_API_KEY: z.string().min(1).optional(),
    NEXT_PUBLIC_ILLOW_SITE_ID: z.string().min(1),
    NEXT_PUBLIC_INTERCOM_API_URL: z.string().default('https://api-iam.intercom.io'),
    NEXT_PUBLIC_INTERCOM_KEY: z.string().min(1),
    NEXT_PUBLIC_SENTRY_DSN: z.string().min(1),
    NEXT_PUBLIC_STRIPE_BILLING_PORTAL: z.string().min(1),
    NEXT_PUBLIC_STRIPE_PRICING_TABLE_ID: z.string().min(1),
    NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY: z.string().min(1),
    NEXT_PUBLIC_UNIFIED_WORKSPACE_ID: z.string().min(1),
    NEXT_PUBLIC_UNIFIED_ENVIRONMENT: z.string().default('Production'),
    NEXT_PUBLIC_USERBACK_TOKEN: z.string().min(1),
    NEXT_PUBLIC_XATA_API_KEY: z.string().min(1).optional(),
  },
  // For Next.js >= 13.4.4, you only need to destructure client variables:
  experimental__runtimeEnv: {
    NEXT_PUBLIC_DEVTOOLS_ENABLED: process.env.NEXT_PUBLIC_DEVTOOLS_ENABLED || 'false',
    NEXT_PUBLIC_GOOGLE_MAPS_CALLBACK_NAME:
      process.env.NEXT_PUBLIC_GOOGLE_MAPS_CALLBACK_NAME || 'googleMapsCallback',
    NEXT_PUBLIC_USERBACK_PORTAL_URL:
      process.env.NEXT_PUBLIC_USERBACK_PORTAL_URL ||
      'https://tech1m.ideas.userback.io/p/N5jg558Wx3',
    NEXT_PUBLIC_UNIFIED_REGION: process.env.NEXT_PUBLIC_UNIFIED_REGION || 'eu',
    NEXT_PUBLIC_SENTRY_ORG: process.env.NEXT_PUBLIC_SENTRY_ORG || 'tedbreesocial',
    NEXT_PUBLIC_SENTRY_PROJECT: process.env.NEXT_PUBLIC_SENTRY_PROJECT || 'tech1m-company-nextjs',
    NEXT_PUBLIC_MICROSOFT_CLARITY_KEY: process.env.NEXT_PUBLIC_MICROSOFT_CLARITY_KEY,
    NEXT_PUBLIC_APP_URL: process.env.NEXT_PUBLIC_APP_URL || 'https://business.tech1m.com',
    NEXT_PUBLIC_BACKEND_URL: process.env.NEXT_PUBLIC_BACKEND_URL || 'https://manage.tech1m.com',
    NEXT_PUBLIC_CAREER_PAGE_DOMAIN:
      process.env.NEXT_PUBLIC_CAREER_PAGE_DOMAIN || 'careers.tech1m.com',
    NEXT_PUBLIC_FRESHPAINT_KEY: process.env.NEXT_PUBLIC_FRESHPAINT_KEY,
    NEXT_PUBLIC_GOOGLE_MAPS_API_KEY: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY || '',
    NEXT_PUBLIC_ILLOW_SITE_ID: process.env.NEXT_PUBLIC_ILLOW_SITE_ID,
    NEXT_PUBLIC_INTERCOM_API_URL:
      process.env.NEXT_PUBLIC_INTERCOM_API_URL || 'https://api-iam.intercom.io',
    NEXT_PUBLIC_INTERCOM_KEY: process.env.NEXT_PUBLIC_INTERCOM_KEY,
    NEXT_PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
    NEXT_PUBLIC_STRIPE_BILLING_PORTAL: process.env.NEXT_PUBLIC_STRIPE_BILLING_PORTAL,
    NEXT_PUBLIC_STRIPE_PRICING_TABLE_ID: process.env.NEXT_PUBLIC_STRIPE_PRICING_TABLE_ID,
    NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY: process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY,
    NEXT_PUBLIC_UNIFIED_WORKSPACE_ID: process.env.NEXT_PUBLIC_UNIFIED_WORKSPACE_ID,
    NEXT_PUBLIC_UNIFIED_ENVIRONMENT: process.env.NEXT_PUBLIC_UNIFIED_ENVIRONMENT || 'Production',
    NEXT_PUBLIC_USERBACK_TOKEN: process.env.NEXT_PUBLIC_USERBACK_TOKEN,
    NEXT_PUBLIC_XATA_API_KEY: process.env.NEXT_PUBLIC_XATA_API_KEY || '',
  },
});
