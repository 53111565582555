import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import cn from '@/utils/cn';
type Props = {
  title?: string;
  icon?: React.ReactNode;
  options?: {
    title: string;
    onClick: () => void;
    selected?: boolean;
  }[];
  children?: React.ReactNode;
  className?: string;
};
export default function ButtonDropdown(props: Props) {
  return <Menu as="div" className={cn('inline-block text-left')} data-sentry-element="Menu" data-sentry-component="ButtonDropdown" data-sentry-source-file="ButtonDropdown.tsx">
      <div>
        <Menu.Button className={cn('flex items-center rounded-full bg-white text-gray-400 hover:text-gray-600 focus:outline-none', 'focus:ring-1 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100', props.className)} data-sentry-element="unknown" data-sentry-source-file="ButtonDropdown.tsx">
          {props.children ? props.children : <>
              <span className="sr-only">{props.title}</span>
              {props.icon ? props.icon : <EllipsisVerticalIcon className="h-5 w-5" />}
            </>}
        </Menu.Button>
      </div>

      <Transition as={Fragment} enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95" data-sentry-element="Transition" data-sentry-source-file="ButtonDropdown.tsx">
        <Menu.Items className="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" data-sentry-element="unknown" data-sentry-source-file="ButtonDropdown.tsx">
          <div className="py-1">
            {props.options?.map(option => <Menu.Item key={option.title}>
                {({
              active
            }) => <button onClick={option.onClick} type="button" className={cn(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block w-full px-4 py-2 text-left text-sm', option.selected ? 'bg-gray-100 font-semibold' : '')}>
                    {option.title}
                  </button>}
              </Menu.Item>)}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>;
}