'use client';

import classNames from 'classnames';
import { usePathname } from 'next/navigation';
import React, { useCallback } from 'react';
import { SidebarSections } from '../types';
import cn from '@/utils/cn';
import Link from 'next/link';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/ui/tooltip';
type FooterProps = {
  navSections: SidebarSections;
  isDesktop?: boolean;
  isCollapsed?: boolean;
};
export default function NavItems({
  navSections,
  isDesktop,
  isCollapsed
}: FooterProps) {
  const pathname = usePathname();
  const isActive = (href: string) => pathname === href || pathname.startsWith(href);
  const linkClassName = useCallback((isCurrent: boolean) => classNames(isCurrent ? 'bg-blue-50 text-primary-default ' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-600', 'group flex gap-x-2.5 p-2 rounded-md text-sm leading-6 font-medium'
  // 'bg-gray-50 text-blue-600 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
  ), []);
  const iconClassName = useCallback((isCurrent: boolean) => classNames(isCurrent ? 'text-primary-default' : 'text-gray-400 group-hover:text-gray-700 hover:stroke-2', 'shrink-0 h-5 w-5 stroke-1.5'), []);
  return <nav className={cn('flex items-center px-4', isDesktop ? 'flex-1 bg-white' : 'pb-6', isCollapsed ? 'pb-0' : '')} data-sentry-component="NavItems" data-sentry-source-file="NavItems.tsx">
      <div className="w-full">
        {navSections.map(section => {
        return <div key={section.id} className={cn(!isCollapsed ? '' : 'flex flex-col items-center justify-center')}>
              <p className="mt-5 pl-2 text-xs font-normal leading-6 text-gray-400">
                {section.category}
              </p>

              {section.navigationItems.map(item => <Link shallow key={item.name} href={item.href} className={linkClassName(isActive(item.href))}>
                  {isCollapsed ? <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger>
                          <item.icon className={iconClassName(isActive(item.href))} aria-hidden="true" />
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>{item.name}</p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider> : <>
                      <item.icon className={iconClassName(isActive(item.href))} aria-hidden="true" />
                      {item.name}
                      {item.isComingSoon && <div className="ml-1 rounded bg-gray-100 p-1.5 text-[10px] leading-none text-gray-700">
                          Coming soon
                        </div>}
                    </>}
                </Link>)}
            </div>;
      })}
      </div>
    </nav>;
}