'use client';
import { toZonedTime } from 'date-fns-tz';
import { intlFormatDistance, isFuture } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import {
  Activity,
  Home,
  Mail,
  Package,
  Layers,
  FileText,
  Settings,
  Globe,
  Users,
} from 'react-feather';
import { SidebarSections } from '../types';
import { WelcomeStatus } from '@/constants/Enums';
import { clearJobPostingLocalStorage } from '@/utils/localStorage';
import { useAppSelector } from '@/store/hooks';
import { selectAnnouncementState, selectAuthState } from '@/store/selectors';
import { useRouter } from 'next/navigation';
import { IoMdColorWand } from 'react-icons/io';
import { PiExam } from 'react-icons/pi';

const navSections: SidebarSections = [
  {
    id: 'main',
    category: '',
    navigationItems: [
      { name: 'Dashboard', href: '/dashboard', icon: Home },
      { name: 'Inbox', href: '/inbox', icon: Mail },
    ],
  },
  {
    id: 'hire',
    category: 'HIRE',
    navigationItems: [
      { name: 'Jobs', href: '/jobs', icon: Package },
      { name: 'Talent CRM', href: '/talent-pool', icon: Users },
      { name: 'Assessments', href: '/assessments', icon: PiExam },
      { name: 'AI Sourcing', href: '/ai-sourcing', icon: IoMdColorWand },
      { name: 'Insights', href: '/insights', icon: Activity, isComingSoon: true },
    ],
  },
  {
    id: 'manage',
    category: 'MANAGE',
    navigationItems: [
      { name: 'Invoices', href: '/invoices', icon: FileText },
      { name: 'Integrations', href: '/integrations', icon: Layers },
      { name: 'Career Hub', href: '/career-hub', icon: Globe },

      // { name: 'Talent Pool', href: '/talent-pool', icon: Briefcase },
      // { name: 'Insights', href: '/insights', icon: Activity, isComingSoon: true },
    ],
  },
  {
    id: 'settings',
    category: '',
    navigationItems: [
      { name: 'Settings', href: '/settings', icon: Settings },
      // { name: 'Help', href: '/help', icon: Layers },
    ],
  },
  // {
  //   category: 'My Talents',
  //   navigationItems: [
  //     { name: 'Onboarding', href: '/onboarding', icon: Clipboard },
  //     { name: 'Talents', href: '/talents', icon: Globe },
  //     { name: 'Time Off', href: '/time-off', icon: Clock },
  //     { name: 'Pay Talents', href: '/pay-talents', icon: DollarSign },
  //     { name: 'Contracts', href: '/contracts', icon: File },
  //   ],
  // },
];

export default function useRootPageLayout() {
  const router = useRouter();

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [showWelcomeModal, setShowWelcomeModal] = useState(false);

  const authState = useAppSelector(selectAuthState);

  const { generalVisible } = useAppSelector(selectAnnouncementState);

  const { user } = authState || {};

  const userSubscription = user?.subscription;

  const subEndsAt = toZonedTime(
    userSubscription?.trial_ends_at || new Date(),
    user?.timezone?.value || Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  const expiresInFuture = isFuture(subEndsAt);

  const subscriptionEndsIn = expiresInFuture
    ? intlFormatDistance(subEndsAt, Date.now(), {
        numeric: 'always',
        unit: 'day',
      })
    : '';

  const announcementText =
    userSubscription?.status === 'trialing'
      ? `Your subscription trial period ${
          subscriptionEndsIn ? `ends ${subscriptionEndsIn}` : 'has elapsed'
        }`
      : '';

  // const announcementCloseable = userSubscription?.status !== 'trialing';

  const closeWelcomeModal = useCallback(() => {
    localStorage.setItem('welcomeStatus', WelcomeStatus.Done);
    setShowWelcomeModal(false);
  }, []);

  const handleWelcomePostAJob = useCallback(() => {
    clearJobPostingLocalStorage();
    router.push('/jobs/post');
    closeWelcomeModal();
  }, [router, closeWelcomeModal]);

  useEffect(() => {
    const welcomeStatus = localStorage.getItem('welcomeStatus');

    if (!welcomeStatus || welcomeStatus !== WelcomeStatus.Done) {
      setShowWelcomeModal(true);
    }
  }, []);

  return {
    sidebarOpen,
    setSidebarOpen,
    showWelcomeModal,
    handleWelcomePostAJob,
    closeWelcomeModal,
    navSections,
    announcementText,
    announceVisible: generalVisible && announcementText,
    // announcementCloseable,
  };
}
