import { getUserTimezone } from './date.helper';
import { getAsPath } from '@/utils/getAsPath';
import Router from '@/utils/windowRouter';

type PostPayload = {
  url: string;
  body?: Record<string, string | number | boolean | object | string[]>;
  files?: {
    dataName: string;
    file: File;
  }[];
  contentType?: string;
  acceptType?: string;
  useFormData?: boolean;
  headers?: RequestInit['headers'];
  token?: string;
  method?: string;
};

export default async function Post(
  url: string,
  body: Record<string, string | number | boolean | object | string[]>,
  contentType?: string,
  acceptType?: string,
  useFormData?: boolean,
  headers?: RequestInit['headers']
) {
  const formData = new FormData();

  if (useFormData && body) {
    Object.entries(body).map(([key, value]) => formData.append(key, value?.toString() || ''));
  }

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': contentType || 'application/json',
      'X-Timezone': getUserTimezone(),
      Accept: acceptType || 'application/json',
      Authorization: `Bearer ${body?.token || ''}`,
      ...headers,
    },
    body: useFormData ? formData : JSON.stringify(body),
  });

  if ([401].some((code) => code === response.status)) {
    localStorage.setItem('authRedirect', getAsPath());

    return Router.replace('/auth/logout');
  }
  if (response) return response.json();

  return { errors: [], message: 'Hmmm...could not process!', status: 'error' };
}

export async function PostAsync({
  url,
  body,
  files,
  contentType,
  acceptType,
  useFormData,
  headers = {},
  token,
  method,
}: PostPayload) {
  const formData = new FormData();
  if (useFormData && body) {
    Object.entries(body).map(([key, value]) => {
      return formData.append(key, value?.toString() || '');
    });
  }

  if (useFormData && files) {
    files.map(({ dataName, file }) => {
      return formData.append(dataName, file, file.name);
    });
  }

  const response = await fetch(url, {
    method: method || 'POST',
    headers: useFormData
      ? {
          Authorization: `Bearer ${token || body?.token || ''}`,
          ...headers,
        }
      : {
          'Content-Type': contentType || 'application/json',
          Accept: acceptType || 'application/json',
          'X-Timezone': getUserTimezone(),
          Authorization: `Bearer ${token || body?.token || ''}`,
          ...headers,
        },
    body: useFormData ? formData : JSON.stringify(body),
  });
  if ([401].some((code) => code === response.status)) {
    localStorage.setItem('authRedirect', getAsPath());

    return Router.replace('/auth/logout');
  }
  if (response) return response.json();

  return { errors: [], message: 'Hmmm...could not process!', status: 'error' };
}
