import { LoaderSpinner } from '@/components/UI';
import React, { Suspense } from 'react';

// Higher-order component to wrap components with `Suspense`
const withSuspense = (Component: (...props: any) => JSX.Element | null) => {
  const SuspenseWrapped = (props: any) => {
    return <Suspense fallback={<div className="flex h-screen w-full items-center justify-center self-center bg-blue-50">
            <LoaderSpinner className="h-10 w-10" />
          </div>} data-sentry-element="Suspense" data-sentry-component="SuspenseWrapped" data-sentry-source-file="withSuspense.tsx">
        <Component {...props} data-sentry-element="Component" data-sentry-source-file="withSuspense.tsx" />
      </Suspense>;
  };
  return SuspenseWrapped;
};
export default withSuspense;