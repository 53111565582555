'use client';

import classNames from 'classnames';
import React from 'react';
import userImage from '@/public/assets/images/user-image.png';
import { useAppSelector } from '@/store/hooks';
import { selectAuthState } from '@/store/selectors';
import { AvatarPrimary, SidebarAccountDropdown } from '@/ui';
import { LogOut } from 'react-feather';
import { useQuery } from '@tanstack/react-query';
type FooterProps = {
  isDesktop?: boolean;
  isCollapsed?: boolean;
};
const useFeedbackPortalUrl = () => useQuery({
  queryKey: ['feedbackPortalUrl'],
  queryFn: () => fetch('/api/feedback-portal-url').then(res => res.json()),
  staleTime: 1000 * 60 * 60 * 24 // 1 day
});
export default function ({
  isDesktop,
  isCollapsed
}: FooterProps) {
  const {
    user
  } = useAppSelector(selectAuthState);
  const {
    data,
    isLoading
  } = useFeedbackPortalUrl();
  const feedbackPortalUrl = data?.url;
  return <div className="bottom-0 border-t border-gray-200 p-4 py-2.5">
      <div className={classNames('group flex w-full flex-shrink-0 items-center justify-between')}>
        <div className="flex items-center">
          <div className={classNames('inline-block overflow-clip rounded-full', isDesktop ? 'h-9 w-9' : 'h-10 w-10')}>
            <AvatarPrimary width={isDesktop ? 24 : 32} height={isDesktop ? 24 : 32} src={user?.avatar || user?.profile_photo_url || userImage} alt="" className="dark-image-mode" />
          </div>
          {!isCollapsed && <div className="ml-3">
              <p className={classNames('font-medium capitalize text-gray-700 group-hover:text-gray-900', isDesktop ? 'text-sm' : 'text-base')}>
                {user ? `${user.first_name} ${user.last_name}` : 'Full Name'}
              </p>
              <p className={classNames('font-medium leading-none text-gray-500 group-hover:text-gray-700', isDesktop ? 'text-xs' : 'text-sm')}>
                {user?.company ? user.company.name : ''}
              </p>
            </div>}
        </div>

        <SidebarAccountDropdown menuItems={[{
        href: ['/settings?tab=My Profile'],
        label: 'Profile'
      }, {
        href: ['/settings?tab=Password'],
        label: 'Account Management'
      }, {
        href: [isLoading ? '#' : feedbackPortalUrl],
        label: 'Feedback Portal',
        target: '_blank',
        rel: 'noopener noreferrer'
      }, {
        href: ['https://intercom.help/tech1m/en/'],
        label: 'Help center',
        target: '_blank',
        rel: 'noopener noreferrer'
      }, {
        href: ['/auth/logout'],
        label: 'Log out',
        divide: true,
        iconRight: <LogOut className="h-5 w-5 text-gray-600 group-hover:text-gray-700" aria-hidden="true" />
      }]} />
      </div>
    </div>;
}